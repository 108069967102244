import { useLocation } from 'react-router-dom';
import { m } from 'framer-motion';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

import { MotionContainer, varFade } from '../../components/animate';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  }
}));

const ToolbarStyleAlt = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

const LogoLink = styled('a')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: 150
  }
}));

const LogoImg = styled(m.img)(({ theme }) => ({
  width: '40%',
  [theme.breakpoints.down('md')]: {
    width: '92%'
  }
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/';

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>

    {isHome &&
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
            backgroundColor: '#141414'
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <LogoLink href="/">
            <LogoImg
              alt="logo"
              src="https://cloudforest.cloud/media/RC-logo-main.png"
              variants={varFade().inUp}
            />
          </LogoLink>
         
          <Box sx={{ flexGrow: 1 }} />

          <Button
            variant="contained"
            rel="noopener"
            href="/contact-us"
          >
            Join Our Waitlist!
          </Button>

          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
        </Container>
      </ToolbarStyle>
      }

{!isHome &&
      <ToolbarStyleAlt
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
            backgroundColor: '#141414'
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <LogoLink href="/">
            <LogoImg
              alt="logo"
              src="https://cloudforest.cloud/media/RC-logo-main.png"
              variants={varFade().inUp}
            />
          </LogoLink>
         
          <Box sx={{ flexGrow: 1 }} />

          <Button
            variant="contained"
            rel="noopener"
            href="/contact-us"
          >
            Join Our Waitlist!
          </Button>

          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
        </Container>
      </ToolbarStyleAlt>
      }

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
