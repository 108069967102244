import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Link, Container, Typography, Stack } from '@mui/material';
// @mui
import { styled, useTheme } from '@mui/material/styles';
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
import { varFade } from '../../components/animate';
import { m } from 'framer-motion';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  const LogoImg = styled(m.img)(({ theme }) => ({
    width: '20%',
    [theme.breakpoints.down('md')]: {
      width: '37%',
    }
  }));

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
          }}
        >
          <Container>

            <LogoImg 
            sx={{ mb: 1, mx: 'auto' }}
            alt="logo"
            src="https://cloudforest.cloud/media/RC-logo-main.png"
            variants={varFade().inUp}
          />

            <Typography variant="caption" component="p">
              © All rights reserved
              <br />
              Konvergence Inc
            </Typography>
          </Container>
        </Box>
      )}
    </Stack>
  );
}
