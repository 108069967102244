import { useLocation, Outlet, Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Box, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';
import MainHeader from './MainHeader';
import { varFade } from '../../components/animate';
import { m } from 'framer-motion';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Minimal',
    children: [
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
      { name: 'FAQs', href: PATH_PAGE.faqs },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: '#' },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'support@minimals.cc', href: '#' },
      { name: 'Los Angeles, 359  Hidden Valley Road', href: '#' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {

  const { pathname } = useLocation();

  const isHome = pathname === '/';

  const LogoImg = styled(m.img)(({ theme }) => ({
    width: '20%',
    [theme.breakpoints.down('md')]: {
      width: '37%',
    }
  }));
  return (
    <RootStyle>
      <Stack sx={{ minHeight: 1 }}>
      <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
          }}
        >
          <Container>

            <LogoImg 
            sx={{ mb: 1, mx: 'auto' }}
            alt="logo"
            src="https://cloudforest.cloud/media/RC-logo-main.png"
            variants={varFade().inUp}
          />

            <Typography variant="caption" component="p">
              © All rights reserved
              <br />
              Konvergence Inc
            </Typography>
          </Container>
        </Box>
    
    </Stack>
    </RootStyle>
  );
}
